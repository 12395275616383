import { useState, useEffect } from "react";
import firebaseServices from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

const useFirebaseAuthCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState({
    email: "",
    photoUrl: "",
    name: "",
  });
  useEffect(() => {
    onAuthStateChanged(firebaseServices.auth, (user) => {
      if (user && user.emailVerified) {
        setCurrentUser({
          ...currentUser,
          email: user.email,
          name: user.displayName,
          photoUrl: user.photoURL,
          uid: user.uid,
        });
      } else {
        setCurrentUser({
          ...currentUser,
          email: "",
          photoUrl: "",
          name: "",
          uid: "",
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  return currentUser;
};

export default useFirebaseAuthCurrentUser;
