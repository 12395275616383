// Import the functions you need from the SDKs you need
import firebaseConfig from "../config/firebaseConfig";
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const firebaseServices = {auth, provider};

export default firebaseServices