import { React } from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, Outlet } from "react-router-dom";

function ConfigurationTypes() {
  const Configurations = {
    "Place Order": "/configure/place-order",
    "Add Order Condition": "/configure/add-order-condition",
  };
  return (
    <>
      {/* {console.log(lastJsonMessage)} */}
      <Container className="main-container" maxWidth="lg">
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {Object.keys(Configurations).map((key, index) => (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              key={index}
              to={Configurations[key]}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ArrowForwardIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={key} />
              </ListItemButton>
            </Link>
          ))}
        </List>
        <Outlet />
      </Container>
    </>
  );
}

export default ConfigurationTypes;
