import "./App.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy } from "react";
import Loadable from "./components/Loadable";
import ConfigurationTypes from "./pages/configurationPage/ConfigurationTypes";
import useFirebaseAuthCurrentUser from "./hooks/firebaseAuthCurrentUser";
import axios from "axios";
import { BACKEND_HTTP } from "./config/config";
import { clearUser, setUser } from "./store/user/slice";

const AddOrderConditionConfig = Loadable(
  lazy(() => import("./pages/configurationPage/AddOrderConditionConfig"))
);
const DashboardLayout = Loadable(
  lazy(() => import("./pages/layouts/DashboardLayout"))
);
const PlaceOrderConfig = Loadable(
  lazy(() => import("./pages/configurationPage/PlaceOrderConfig"))
);
const RealTimeDataTable = Loadable(
  lazy(() => import("./pages/mainDashboardPage/LiveOrderConditions"))
);
const TradeListView = Loadable(
  lazy(() => import("./pages/analysisPage/TradeListView.jsx"))
);
const TradeListCryptoView = Loadable(
  lazy(() => import("./pages/analysisPage/TradeListView-crypto.jsx"))
);
const MonitorView = Loadable(
  lazy(() => import("./pages/monitor/monitorLayout.jsx"))
);
const LiveView = Loadable(
  lazy(() => import("./pages/configurationPage/live.jsx"))
);
const LiveCryptoView = Loadable(
  lazy(() => import("./pages/configurationPage/live-crypto.jsx"))
);
const CloseAllPositionsView = Loadable(
  lazy(() => import("./pages/configurationPage/closeAllPositions.jsx"))
);
const EditStratPage = Loadable(
  lazy(() => import("./pages/EditStratPage/edit.jsx"))
);
const PortfolioPage = Loadable(
  lazy(() => import("./pages/portfolio/portfolio.jsx"))
);

function App() {
  const dispatch = useDispatch();
  const currentUser = useFirebaseAuthCurrentUser();

  useEffect(() => {
    // Verifying Current user with Backend
    const { email, name, uid } = currentUser;
    if (email && name && uid) {
      const userInfo = {
        email: email,
        fullName: name,
        userId: uid,
      };

      axios
        .post(`${BACKEND_HTTP}/api/user/login`, userInfo)
        .then((res) => {
          const token = res.data?.token;
          dispatch(
            setUser({
              email: email,
              fullName: name,
              uid: uid,
              token: token,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Clear User Info from Store
      dispatch(clearUser());
    }
  }, [currentUser]);

  // Routes
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <DashboardLayout />,
        children: [
          {
            path: "/",
            element: <RealTimeDataTable />,
          },
          {
            path: "/configure",
            element: <ConfigurationTypes />,
            children: [
              {
                path: "add-order-condition",
                element: <AddOrderConditionConfig />,
              },
              {
                path: "place-order",
                element: <PlaceOrderConfig />,
              },
            ],
          },
          {
            path: "/analysis",
            element: <TradeListView />,
          },
          {
            path: "/analysis-crypto",
            element: <TradeListCryptoView />,
          },
          {
            path: "/live",
            element: <LiveView />,
          },
          {
            path: "/live-crypto",
            element: <LiveCryptoView />,
          },
          {
            path: "/portfolio",
            element: <PortfolioPage />,
          },
          {
            path: "/edit",
            element: <EditStratPage />,
          },
          {
            path: "/close/positions/all",
            element: <CloseAllPositionsView />,
          },
          {
            path: "/monitor",
            element: <MonitorView />,
          },
        ],
      },
    ],
    { basename: "/" }
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
