// userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducer";

const userSlice = createSlice({
  name: "user",
  initialState: {
    email: "",
    fullName: "",
    uid: "",
    token: "",
  },
  reducers: reducers,
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
