export const reducers = {
    setUser: (state, action) => {
        return {
            ...state,
            email: action.payload.email,
            fullName: action.payload.fullName,
            uid: action.payload.uid,
            token: action.payload.token,
        };
    },
    clearUser: (state) => {
        return {
            email: "",
            fullName: "",
            uid: "",
            token: "",
        };
    },
};